import React from 'react';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BaseLayout from './app/layout/base-layout/base-layout';
import GenerateStatements from './app/lib/generate-statements';
import GetUserPermissions from './app/lib/get-user-permissions';
import ScrollToTop from './app/lib/scroll-to-top';
import routes, { routesMap } from './routers';
import ProtectedRoute from './routers/protected-route';
import ErrorFallback from './shared/ui/error-fallback';
import PageLoader from './shared/ui/page-loader';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
	return (
		<React.StrictMode>
			<QueryClientProvider client={queryClient}>
				<ToastContainer
					position="top-center"
					autoClose={5000}
					role="alert"
					newestOnTop={true}
					theme="colored"
					transition={Slide}
				/>
				<BrowserRouter>
					<ScrollToTop />
					<GetUserPermissions />
					<GenerateStatements />
					<BaseLayout>
						<Sentry.ErrorBoundary
							fallback={({ error, resetError }) => (
								<ErrorFallback error={error} resetErrorBoundary={resetError} />
							)}
						>
							<React.Suspense fallback={<PageLoader />}>
								<SentryRoutes>
									{routes.map((route) => {
										const { path, Component, protect } = route;
										if (protect) {
											return (
												<Route
													path={path}
													element={
														<ProtectedRoute>
															<Component />
														</ProtectedRoute>
													}
													key={path}
												/>
											);
										}
										return <Route path={path} element={<Component />} key={path} />;
									})}
									<Route
										path="*"
										element={
											<Navigate
												to={
													process.env.REACT_APP_SENTRY_ENVIRONMENT === 'prod'
														? routesMap.exchange_rates
														: routesMap.distributors_reports
												}
											/>
										}
									/>
								</SentryRoutes>
							</React.Suspense>
						</Sentry.ErrorBoundary>
					</BaseLayout>
				</BrowserRouter>
			</QueryClientProvider>
		</React.StrictMode>
	);
};

export default App;
