import React, { ComponentType, LazyExoticComponent } from 'react';

const UploadReport = React.lazy(() => import('pages/upload-report'));
const DistributorsReports = React.lazy(() => import('pages/distributors-reports'));
const DistributorsReport = React.lazy(() => import('pages/distributors-report'));
const AuthorsReports = React.lazy(() => import('pages/contributors-reports/contributors-reports'));
const AuthorReports = React.lazy(() => import('pages/contributor-reports/contributor-reports'));
const ExchangeRates = React.lazy(() => import('pages/exchange-rates'));
const ExchangeArchiveRates = React.lazy(() => import('pages/exchange-archive-rates'));
const EditionsReports = React.lazy(() => import('pages/editions-reports'));
const EditionReport = React.lazy(() => import('pages/edition-report'));
const Admin = React.lazy(() => import('pages/admin'));
const RoyaltyStatements = React.lazy(() => import('pages/royalty-statements'));

type TNames =
	| 'distributors_reports'
	| 'distributors_report'
	| 'upload_report'
	| 'reports'
	| 'contributors_reports'
	| 'contributor_reports'
	| 'editions_reports'
	| 'edition_report'
	| 'report_details'
	| 'royalty_statements'
	| 'exchange_rates'
	| 'exchange_archive_rates'
	| 'admin';

type TRoutes = {
	name: TNames;
	path: string;
	protect: boolean;
	Component: LazyExoticComponent<ComponentType<any>>;
};

const getRoutes = (): TRoutes[] => {
	if (process.env.REACT_APP_SENTRY_ENVIRONMENT === 'prod') {
		return [
			{
				name: 'exchange_rates',
				path: '/exchange-rates',
				protect: false,
				Component: ExchangeRates,
			},
			{
				name: 'exchange_archive_rates',
				path: '/exchange-rates/archive',
				protect: false,
				Component: ExchangeArchiveRates,
			},
		];
	}
	return [
		{
			name: 'distributors_reports',
			path: '/distributors-reports',
			protect: false,
			Component: DistributorsReports,
		},
		{
			name: 'distributors_report',
			path: '/distributors-reports/:reportId',
			protect: false,
			Component: DistributorsReport,
		},
		{
			name: 'upload_report',
			path: '/distributors-reports/upload-report',
			protect: false,
			Component: UploadReport,
		},
		{
			name: 'contributors_reports',
			path: '/contributors-reports',
			protect: false,
			Component: AuthorsReports,
		},
		{
			name: 'contributor_reports',
			path: '/contributors-reports/:contributorId',
			protect: false,
			Component: AuthorReports,
		},
		{
			name: 'editions_reports',
			path: '/editions-reports',
			protect: false,
			Component: EditionsReports,
		},
		{
			name: 'edition_report',
			path: '/editions-reports/:edition',
			protect: false,
			Component: EditionReport,
		},
		{
			name: 'exchange_rates',
			path: '/exchange-rates',
			protect: false,
			Component: ExchangeRates,
		},
		{
			name: 'royalty_statements',
			path: '/royalty-statements',
			protect: false,
			Component: RoyaltyStatements,
		},
		{
			name: 'exchange_archive_rates',
			path: '/exchange-rates/archive',
			protect: false,
			Component: ExchangeArchiveRates,
		},
		{
			name: 'admin',
			path: '/admin',
			protect: true,
			Component: Admin,
		},
	];
};
const routes: TRoutes[] = getRoutes();

const routesMap: { [key in TNames]?: string } = {};

routes.forEach((route) => {
	if (route.hasOwnProperty('name')) {
		routesMap[route.name] = route.path;
	}
});

const urlBuilder = (name: TNames, params?: { [key: string]: any }) => {
	if (!routesMap.hasOwnProperty(name)) {
		return null;
	}

	let url = routesMap[name]; // news/:id

	for (const key in params) {
		url = url.replace(`:${key}`, params[key]);
	}

	return url;
};

export default routes;
export { routesMap, urlBuilder };
