import { FC, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { routesMap } from 'routers';

import { getMe, usersKeyStore } from 'shared/api/users';
import Frame from 'shared/ui/frame';
import FrameBody from 'shared/ui/frame/frame-body';
import FrameHeader from 'shared/ui/frame/frame-header';

import styles from './styles.module.scss';
import { TNavigationItem } from './types';
import NavigationItem from './ui/navigation-item';
import NavigationItemDropdown from './ui/navigation-item-dropdown';
import NavigationLink from './ui/navigation-link';

const Navigation: FC = () => {
	const { data } = useQuery({
		queryKey: [usersKeyStore.user.me],
		queryFn: getMe,
	});

	const isAdmin = data?.data?.authority === 'ROLE_ADMIN';

	const urls: TNavigationItem[] = useMemo(() => {
		if (process.env.REACT_APP_SENTRY_ENVIRONMENT === 'prod') {
			return [
				{
					text: 'Exchange rates',
					url: routesMap.exchange_rates,
				},
			];
		}

		const base = [
			{
				text: 'Distributors reports',
				url: routesMap.distributors_reports,
			},
			{
				text: 'Royalties Reports',
				dropdown: [
					{
						text: 'Editions reports',
						url: routesMap.editions_reports,
					},
					{
						text: 'Contributors reports',
						url: routesMap.contributors_reports,
					},
				],
			},
			{
				text: 'Royalty statements',
				url: routesMap.royalty_statements,
			},
			{
				text: 'Exchange rates',
				url: routesMap.exchange_rates,
			},
		];

		if (isAdmin) {
			base.push({
				text: 'Admin',
				url: routesMap.admin,
			});
		}

		return base;
	}, [isAdmin]);

	return (
		<Frame>
			<FrameHeader title="Royalties Navigation" />
			<FrameBody>
				<ul className={styles.list}>
					{urls.map((item) => {
						if (item?.dropdown) {
							return (
								<NavigationItemDropdown key={item.text} dropdown={item.dropdown} text={item.text} />
							);
						}
						if (item.url) {
							return (
								<NavigationItem key={item.url}>
									<NavigationLink url={item.url}>{item.text}</NavigationLink>
								</NavigationItem>
							);
						}
					})}
				</ul>
			</FrameBody>
		</Frame>
	);
};

export default Navigation;
