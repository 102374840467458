import React from 'react';

import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';

import App from './app';
import './styles/main.scss';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			),
		}),
		new Sentry.Replay(),
	],
	release: process.env.REACT_APP_VERSION,
	tracesSampleRate: process.env.NODE_ENV === 'development' ? 0 : 0.25,
	environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
	replaysSessionSampleRate: process.env.REACT_APP_SENTRY_ENVIRONMENT === 'staging' ? 0.5 : 0.1,
	replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);
